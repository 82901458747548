<template>
	<div class="footbar">
		<div class="footbar-wrapper">
			<div class="footbar-content">
				<div class="footbar-content-links">
					<a href="#pirces-block">Купить</a>
					<landing-button @click.stop="$emit('showFeedback', 'demo')" link>Получить демо</landing-button>
					<a :href="accountHref">Личный кабинет</a>
					<a
						href="https://affine.vrchemlab.ru/share/108c165a-04af-41f3-8c45-33ef7c78fee5/Byd_asLlHV"
						target="_blank"
						>База знаний</a
					>
				</div>
				<div class="footbar-content-logo">
					<chemlab-icon />
					<span>VR Chemistry Lab</span>
				</div>
				<div class="footbar-content-copyright">
					<span> &copy; {{ new Date().getFullYear() }} ООО «СТЕМ-игры»</span>
					<router-link :to="{ name: 'Privacy' }">Конфиденциальность</router-link>
					<router-link :to="{ name: 'UserAgreement' }" class="text-center">Соглашение с конечным пользователем</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LandingButton from '@/components/LandingButton.vue';
import ChemlabIcon from '@/components/icons/ChemlabIcon.vue';
import { navbarMixin } from '@/mixins';

export default {
	mixins: [navbarMixin],
	components: {
		LandingButton,
		ChemlabIcon,
	},
};
</script>

<style lang="scss" scoped>
.footbar {
	display: flex;
	justify-content: center;
	width: 100%;

	&-wrapper {
		display: flex;
		justify-content: center;
		width: 81.25rem;
		padding: 2.5rem 0;
		border-top: 1px solid $footbar-border;

		@include media-breakpoint-down(lg) {
			width: 100%;
		}
	}

	&-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2.5rem;
		width: 35.375rem;

		@include media-breakpoint-down(sm) {
			gap: 1.5rem;
		}

		&-links {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
				gap: 1.25rem;
			}

			a,
			.landing-button {
				@extend %roboto-link;
				color: $primary-dark;
				text-decoration: unset;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		&-logo {
			@extend %jura-label;
			display: flex;
			align-items: center;
			gap: 1.125rem;
			color: $text-primary;
		}

		&-copyright {
			display: flex;
			align-items: center;
			gap: 1.5rem;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
				gap: 1.25rem;
			}

			span {
				color: $text-secondary;
			}

			a {
				@extend %roboto-link;
				color: $primary-dark;
				text-decoration: unset;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
</style>
